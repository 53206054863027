import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { CHECKLIST_STEP_KEYS } from "../components/checklist-sidebar/data/checklist-step";

@Injectable({
	providedIn: "root"
})
export class OnboardingService {
	private openModalStep = new Subject<CHECKLIST_STEP_KEYS>();

	openModalStep$ = this.openModalStep.asObservable();

	private closeModalStep = new Subject<CHECKLIST_STEP_KEYS>();

	closeModalStep$ = this.closeModalStep.asObservable();

	constructor() {}

	setCloseModalStep(key: CHECKLIST_STEP_KEYS): void {
		this.closeModalStep.next(key);
	}

	fireOpenModalStep(step: CHECKLIST_STEP_KEYS): void {
		this.openModalStep.next(step);
	}
}
