import { CHECKLIST_STEP_KEYS } from "./checklist-step";

type TRACKING_KEYS =
	| "NFe_config"
	| "taxes"
	| "financial"
	| "monthly_billing"
	| "comercial";

const checklistStepKeyToTrackingKey = new Map<
	CHECKLIST_STEP_KEYS,
	TRACKING_KEYS
>()
	.set("SETUP_INVOICE", "NFe_config")
	.set("SETUP_TAX", "taxes")
	.set("FINANCIAL_MANAGER", "financial")
	.set("INVOICING_MANAGER", "monthly_billing")
	.set("COMMERCIAL_MANAGER", "comercial");

export { checklistStepKeyToTrackingKey };
