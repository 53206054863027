import {
	ChangeDetectionStrategy,
	Component,
	computed,
	CUSTOM_ELEMENTS_SCHEMA,
	OnDestroy,
	OnInit,
	signal
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ChecklistSidebarItemComponent } from "./checklist-sidebar-item/checklist-sidebar-item.component";
import { ChecklistSidebarBodyComponent } from "./checklist-sidebar-body/checklist-sidebar-body.component";
import { ChecklistSidebarModalComponent } from "./checklist-sidebar-modal/checklist-sidebar-modal.component";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { OnboardingService } from "@modules/home/service/onboarding.service";
import { of, Subject, switchMap, takeUntil } from "rxjs";
import { CHECKLIST_STEP, CHECKLIST_STEP_KEYS } from "./data/checklist-step";
import { AuthService } from "@core/services/auth/auth.service";
import { OnboardingUserService } from "@data/services/onboarding-user.service";
import { ChecklistSidebarFooterComponent } from "./checklist-sidebar-footer/checklist-sidebar-footer.component";
import { OnboardingUser } from "@data/schemas/onboarding-user";
import { ChecklistSidebarModalFinishedComponent } from "./checklist-sidebar-modal-finished/checklist-sidebar-modal-finished.component";
import { CustomSegmentService } from "@core/services/custom-segment.service";

@Component({
	selector: "app-checklist-sidebar",
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		CommonModule,
		TranslateModule,
		ChecklistSidebarItemComponent,
		ChecklistSidebarBodyComponent,
		ChecklistSidebarFooterComponent,
		ChecklistSidebarModalComponent
	],
	templateUrl: "./checklist-sidebar.component.html",
	styleUrl: "./checklist-sidebar.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistSidebarComponent implements OnInit, OnDestroy {
	constructor(
		private atlasModalService: AtlasModalService,
		private onboardingService: OnboardingService,
		private onboardingUserService: OnboardingUserService,
		private automataTranslateService: AutomataTranslateService,
		private authService: AuthService,
		private segment: CustomSegmentService
	) {}

	protected isExpanded = false;

	protected authorityType = signal<string | undefined>("");

	protected actualOnboardingUserData = computed(() =>
		this.onboardingUserService.onbordingUserData()
	);

	protected actualSteps = computed(() => {
		const onboardingData = this.actualOnboardingUserData() ?? {};
		return Object.keys(onboardingData)
			.filter(
				(key) =>
					key !== "welcomeDate" &&
					key !== "showWelcomeModal" &&
					key !== "checklist"
			)
			.reduce(
				(obj, key) => {
					return {
						...obj,
						[key]: onboardingData[
							key as keyof typeof onboardingData
						]
					};
				},
				{} as Omit<
					OnboardingUser,
					"welcomeDate" | "showWelcomeModal" | "checklist"
				>
			);
	});

	protected completedStepsNumber = computed(() => {
		return Object.values(this.actualSteps()).reduce(
			(acc: number, cur: unknown) => {
				if (cur && typeof cur === "boolean") {
					return acc + 1;
				}
				return acc;
			},
			1
		);
	});

	protected stepsNumber = computed(() => {
		if (this.authorityType() === "ADM") {
			return 6;
		}
		if (this.authorityType() === "FINANCEIRO") {
			return 4;
		}
		if (this.authorityType() === "VENDEDOR") {
			return 2;
		}
		return 0;
	});

	protected percentageSteps = computed(() => {
		return Math.min(
			Math.ceil((this.completedStepsNumber() / this.stepsNumber()) * 100),
			100
		);
	});

	protected isAllStepsChecked = computed(() => {
		return this.percentageSteps() === 100;
	});

	private _onDestroy = new Subject<boolean>();

	private apiKeysMap = new Map<CHECKLIST_STEP_KEYS, string>()
		.set("SETUP_INVOICE", "nfeChecked")
		.set("SETUP_TAX", "taxesChecked")
		.set("FINANCIAL_MANAGER", "financialChecked")
		.set("INVOICING_MANAGER", "billingChecked")
		.set("COMMERCIAL_MANAGER", "commercialChecked");

	private localKeyToApiKey(key: CHECKLIST_STEP_KEYS): string | undefined {
		return this.apiKeysMap.get(key);
	}

	private openModalStepWatch(): void {
		this.onboardingService.openModalStep$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((key) => {
				const modalData = CHECKLIST_STEP[key];
				this.atlasModalService.openModal(
					ChecklistSidebarModalComponent,
					{
						initialState: {
							title: this.automataTranslateService.getTranslationSync(
								modalData.title
							),
							key,
							authority: this.authorityType,
							videoId: modalData.videoId,
							description:
								this.automataTranslateService.getTranslationSync(
									modalData.description
								),
							okButtonText:
								this.automataTranslateService.getTranslationSync(
									"ONBOARDING.OK_BUTTON"
								),
							altButtonTextPlayerYt:
								this.automataTranslateService.getTranslationSync(
									"ONBOARDING.ALT_BUTTON_YT"
								)
						}
					}
				);
			});
	}

	private closeModalStepWatch(): void {
		this.onboardingService.closeModalStep$
			.pipe(
				switchMap((key) => {
					const apiKeyOnboarding = this.localKeyToApiKey(key);
					const onboardingUserData = this.actualOnboardingUserData();
					const userId =
						this.authService.getUserLogin()?.idUsuario || 0;
					if (apiKeyOnboarding) {
						return this.onboardingUserService
							.updateOnboardingUser(userId, {
								...onboardingUserData,
								[apiKeyOnboarding]: true
							})
							.pipe(
								switchMap(() =>
									this.onboardingUserService.getOnboardingUser(
										userId
									)
								)
							);
					}
					return of();
				}),
				takeUntil(this._onDestroy)
			)
			.subscribe();
	}

	ngOnInit(): void {
		this.authorityType.set(this.authService.getAuthorityAsaas());
		this.openModalStepWatch();
		this.closeModalStepWatch();
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private trackFinishBtnClick(): void {
		this.segment.track({
			action: "finish",
			table: "base_erp_onboarding",
			screen: "checklist"
		});
	}

	protected toggleExpansion() {
		this.isExpanded = !this.isExpanded;
	}

	protected onFinishedButtonClick(): void {
		this.trackFinishBtnClick();
		this.atlasModalService.openModal(
			ChecklistSidebarModalFinishedComponent
		);
	}
}
