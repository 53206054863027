@if (showFooter()) {
	<div class="mt-2 mb-3">
		<hr />
		<div class="footer-onboarding">
			<atlas-button
				(click)="emitFinishedButtonClickEvent()"
				type="filled"
				theme="primary"
				[description]="'ONBOARDING.FOOTER.FINISH_BUTTON' | translate"
				icon="check-circle"
			></atlas-button>
		</div>
	</div>
}
