<div class="checklist-item atlas-spacing-mb-2 cursor-pointer">
	<div class="text-container">
		<atlas-text
			(click)="openModal()"
			class="checklist-text"
			[strikethrough]="isChecked"
			[muted]="isChecked"
		>
			<ng-content></ng-content>
		</atlas-text>
		@if (isChecked && key !== "FIRST_ACCESS") {
			<div class="subtitle-container">
				<img
					src="assets/icons/onboarding-checklist/video.svg"
					alt="video"
				/>
				<span (click)="fireSubtitleEvent()" class="subtitle">
					{{ "ONBOARDING.WATCH_VIDEO_AGAIN" | translate }}
				</span>
			</div>
		}
	</div>

	<atlas-image
		(click)="openModal()"
		class="checklist-img cursor-pointer"
		[src]="
			isChecked
				? 'assets/icons/onboarding-checklist/checked-bg.svg'
				: 'assets/icons/onboarding-checklist/icon-bg.svg'
		"
	></atlas-image>
</div>
