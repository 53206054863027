const CHECKLIST_STEP = {
	FIRST_ACCESS: { title: "", description: "", videoId: "" },
	SETUP_INVOICE: {
		title: "ONBOARDING.CHECKLIST_ITEM.SETUP_INVOICE",
		description: "ONBOARDING.DESCRIPTION.SETUP_INVOICE",
		videoId: "l78I331_eEQ"
	},
	SETUP_TAX: {
		title: "ONBOARDING.CHECKLIST_ITEM.SETUP_TAX",
		description: "ONBOARDING.DESCRIPTION.SETUP_TAX",
		videoId: "jsydHRZj7zA"
	},
	FINANCIAL_MANAGER: {
		title: "ONBOARDING.CHECKLIST_ITEM.FINANCIAL_MANAGER",
		description: "ONBOARDING.DESCRIPTION.FINANCIAL_MANAGER",
		videoId: "pKLjJsz3Z8E"
	},
	INVOICING_MANAGER: {
		title: "ONBOARDING.CHECKLIST_ITEM.INVOICING_MANAGER",
		description: "ONBOARDING.DESCRIPTION.INVOICING_MANAGER",
		videoId: "bU6W2EqUpuY"
	},
	COMMERCIAL_MANAGER: {
		title: "ONBOARDING.CHECKLIST_ITEM.COMMERCIAL_MANAGER",
		description: "ONBOARDING.DESCRIPTION.COMMERCIAL_MANAGER",
		videoId: "-9ujN9ePAIY"
	}
} as const;

type CHECKLIST_STEP_KEYS = keyof typeof CHECKLIST_STEP;

export { CHECKLIST_STEP, CHECKLIST_STEP_KEYS };
