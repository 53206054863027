<div class="modal-header modal-highlight">
	<div class="checklist-container" [ngClass]="{ expanded: isExpanded }">
		<div>
			<atlas-button
				class="checklist-button"
				(click)="toggleExpansion()"
				type="filled"
				size="md"
				description="{{ 'ONBOARDING.FIRST_STEPS_TITLE' | translate }}"
				icon="list"
				>{{ "ONBOARDING.FIRST_STEPS_TITLE" | translate }}</atlas-button
			>
		</div>

		<div>
			@if (isExpanded) {
				<div
					class="checklist-box"
					[class.padding-without-footer]="!isAllStepsChecked()"
				>
					<div class="checklist-title">
						<atlas-heading size="h5" alignment="left">
							{{
								"ONBOARDING.FIRST_STEPS_TITLE" | translate
							}}</atlas-heading
						>
						<atlas-text
							size="sm"
							theme="secondary"
							muted=""
							line-clamp="20"
							theme-variation="400"
						>
							{{
								"ONBOARDING.FIRST_STEPS_SUBTITLE" | translate
							}}</atlas-text
						>
						<atlas-progress-bar
							class="checklist-progress-bar"
							min-value="0"
							max-value="100"
							[value]="percentageSteps()"
							unit="percentage"
							show-label="true"
							orientation="horizontal"
						>
						</atlas-progress-bar>
					</div>
					<div class="w-full">
						<app-checklist-sidebar-body
							[authorityType]="authorityType()"
							[onboardingUserData]="actualOnboardingUserData()"
						/>
						<app-checklist-sidebar-footer
							[showFooter]="isAllStepsChecked()"
							(onFinishedButtonClick)="onFinishedButtonClick()"
						/>
					</div>
				</div>
			}
		</div>
	</div>
</div>
