<atlas-modal
	#atlasModal
	[header]="title"
	open=""
	size="large"
	[disableAutoClose]="true"
	(atlas-modal-close)="closeModal()"
>
	<atlas-text>{{ description }}</atlas-text>

	<div class="d-flex justify-content-center atlas-spacing-mt-3">
		<youtube-player
			width="720"
			height="388"
			[videoId]="videoId"
			[placeholderButtonLabel]="altButtonTextPlayerYt"
			(stateChange)="onPlayerReady($event)"
		/>
	</div>

	<atlas-button
		(click)="closeModalButton()"
		[description]="okButtonText"
		theme="primary"
		slot="actions"
	></atlas-button
></atlas-modal>
