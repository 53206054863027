import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { OnboardingUserService } from "@data/services/onboarding-user.service";
import { AuthService } from "@core/services/auth/auth.service";
import { OnboardingUser } from "@data/schemas/onboarding-user";
import { DateTime } from "luxon";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { OnboardingService } from "@modules/home/service/onboarding.service";

@Component({
	selector: "app-welcome-modal",
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [CommonModule, TranslateModule],
	templateUrl: "./welcome-modal.component.html",
	styleUrl: "./welcome-modal.component.scss"
})
export class WelcomeModalComponent implements OnInit {
	protected isModalOpen!: boolean;
	protected onboardingUserId!: number;

	constructor(
		private onboardingUserService: OnboardingUserService,
		private authService: AuthService,
		private segment: CustomSegmentService,
		private onboardingService: OnboardingService
	) {}

	ngOnInit() {
		this.onboardingUserId = this.authService.getUserLogin()?.idUsuario || 0;
		this.segmentTrackShowWelcomeModal();
	}

	protected startNowButton() {
		this.updateOnboardingUser();

		this.isModalOpen = false;
		this.segmentTrackStartButton();
	}

	protected closeModal() {
		this.updateOnboardingUser();

		this.isModalOpen = false;
		this.segmentTrackCancelButton();
	}

	private updateOnboardingUser() {
		const currentDate = DateTime.now();
		const formattedDate = currentDate.toFormat("yyyy-MM-dd'T'HH:mm:ss");

		const updatePayload = {
			welcomeDate: formattedDate
		} as OnboardingUser;

		this.onboardingUserService
			.updateOnboardingUser(this.onboardingUserId, updatePayload)
			.subscribe(() => {
				this.onboardingUserService.emitForceUpdateOnboardingUser();
			});
	}

	protected segmentTrackShowWelcomeModal(): void {
		this.segment.track({
			table: "welcome_modal",
			screen: "Home",
			action: "Visualização do Modal"
		});
	}

	protected segmentTrackStartButton(): void {
		this.segment.track({
			table: "welcome_modal",
			screen: "Home",
			action: "Clique no 'Começar agora'"
		});
	}

	protected segmentTrackCancelButton(): void {
		this.segment.track({
			table: "welcome_modal",
			screen: "Home",
			action: "Clique no 'X'"
		});
	}
}
