<ng-container [ngSwitch]="authorityType()">
	<ng-container
		*ngSwitchCase="'ADM'"
		[ngTemplateOutlet]="admin"
	></ng-container>
	<ng-container
		*ngSwitchCase="'FINANCEIRO'"
		[ngTemplateOutlet]="finance"
	></ng-container>
	<ng-container
		*ngSwitchCase="'VENDEDOR'"
		[ngTemplateOutlet]="seller"
	></ng-container>
</ng-container>

<ng-template #admin>
	<app-checklist-sidebar-item key="FIRST_ACCESS" [isChecked]="true">
		{{ "ONBOARDING.CHECKLIST_ITEM.FIRST_ACESS" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="SETUP_INVOICE"
		[isChecked]="onboardingUserData()?.nfeChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.SETUP_INVOICE" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="SETUP_TAX"
		[isChecked]="onboardingUserData()?.taxesChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.SETUP_TAX" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="FINANCIAL_MANAGER"
		[isChecked]="onboardingUserData()?.financialChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.FINANCIAL_MANAGER" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="INVOICING_MANAGER"
		[isChecked]="onboardingUserData()?.billingChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.INVOICING_MANAGER" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="COMMERCIAL_MANAGER"
		[isChecked]="onboardingUserData()?.commercialChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.COMMERCIAL_MANAGER" | translate }}
	</app-checklist-sidebar-item>
</ng-template>

<ng-template #finance>
	<app-checklist-sidebar-item key="FIRST_ACCESS" [isChecked]="true">
		{{ "ONBOARDING.CHECKLIST_ITEM.FIRST_ACESS" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="FINANCIAL_MANAGER"
		[isChecked]="onboardingUserData()?.financialChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.FINANCIAL_MANAGER" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="INVOICING_MANAGER"
		[isChecked]="onboardingUserData()?.billingChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.INVOICING_MANAGER" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="COMMERCIAL_MANAGER"
		[isChecked]="onboardingUserData()?.commercialChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.COMMERCIAL_MANAGER" | translate }}
	</app-checklist-sidebar-item>
</ng-template>

<ng-template #seller>
	<app-checklist-sidebar-item key="FIRST_ACCESS" [isChecked]="true">
		{{ "ONBOARDING.CHECKLIST_ITEM.FIRST_ACESS" | translate }}
	</app-checklist-sidebar-item>

	<app-checklist-sidebar-item
		key="COMMERCIAL_MANAGER"
		[isChecked]="onboardingUserData()?.commercialChecked"
	>
		{{ "ONBOARDING.CHECKLIST_ITEM.COMMERCIAL_MANAGER" | translate }}
	</app-checklist-sidebar-item>
</ng-template>
