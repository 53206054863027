import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	input,
	output
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-checklist-sidebar-footer",
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [CommonModule, TranslateModule],
	templateUrl: "./checklist-sidebar-footer.component.html",
	styleUrl: "./checklist-sidebar-footer.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistSidebarFooterComponent {
	showFooter = input.required<boolean>();

	onFinishedButtonClick = output<void>();

	emitFinishedButtonClickEvent(): void {
		this.onFinishedButtonClick.emit();
	}
}
