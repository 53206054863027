import { CommonModule } from "@angular/common";
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	Input,
	booleanAttribute
} from "@angular/core";
import { OnboardingService } from "@modules/home/service/onboarding.service";
import { TranslateModule } from "@ngx-translate/core";
import { CHECKLIST_STEP, CHECKLIST_STEP_KEYS } from "../data/checklist-step";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { checklistStepKeyToTrackingKey } from "../data/checklist-step-key-to-tracking-key";

@Component({
	selector: "app-checklist-sidebar-item",
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: "./checklist-sidebar-item.component.html",
	styleUrl: "./checklist-sidebar-item.component.scss",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistSidebarItemComponent {
	@Input({ required: true })
	key: CHECKLIST_STEP_KEYS = "FIRST_ACCESS";

	@Input({ required: true, transform: booleanAttribute })
	isChecked = false;

	@Input({ required: false })
	subtitle = "";

	constructor(
		private onboardingService: OnboardingService,
		private window: Window,
		private segment: CustomSegmentService
	) {}

	private getTrackingKeyItem(): string {
		return `checklist_step_${checklistStepKeyToTrackingKey.get(this.key)}`;
	}

	private trackWatchAgainClick(): void {
		this.segment.track({
			action: "watch_again",
			table: "base_erp_onboarding",
			screen: this.getTrackingKeyItem()
		});
	}

	protected fireSubtitleEvent(): void {
		const link = this.window.open();
		this.trackWatchAgainClick();
		if (link) {
			link.opener = null;
			link.location = `https://www.youtube.com/watch?v=${CHECKLIST_STEP[this.key].videoId}`;
		}
	}

	protected openModal(): void {
		if (this.key === "FIRST_ACCESS" || this.isChecked) {
			return;
		}
		this.onboardingService.fireOpenModalStep(this.key);
	}
}
