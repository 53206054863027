import { CommonModule } from "@angular/common";
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ViewChild,
	ElementRef,
	computed,
	signal
} from "@angular/core";
import { AuthService } from "@core/services/auth/auth.service";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { OnboardingUserService } from "@data/services/onboarding-user.service";
import { TranslateModule } from "@ngx-translate/core";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";
import { IAtlasModal } from "@shared/types/atlas/i-atlas-modal";

@Component({
	selector: "app-checklist-sidebar-modal-finished",
	standalone: true,
	imports: [CommonModule, TranslateModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	templateUrl: "./checklist-sidebar-modal-finished.component.html",
	styleUrl: "./checklist-sidebar-modal-finished.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistSidebarModalFinishedComponent {
	constructor(
		private atlasModalService: AtlasModalService,
		private onboardingUserService: OnboardingUserService,
		private authService: AuthService,
		private segment: CustomSegmentService
	) {}

	@ViewChild("atlasModal")
	atlasModal!: ElementRef<IAtlasModal>;

	protected loadingFinishOnboardingBtn = signal<boolean>(false);

	protected actualOnboardingUserData = computed(() =>
		this.onboardingUserService.onbordingUserData()
	);

	private trackConclusionModal(
		action: "close" | "finish" | "help_center"
	): void {
		this.segment.track({
			action,
			table: "base_erp_onboarding",
			screen: "conclusion_modal"
		});
	}

	private hide(): void {
		this.atlasModal.nativeElement?.closeModal?.();
	}

	protected trackHelpCenterClick(): void {
		this.trackConclusionModal("help_center");
	}

	protected closeModal(): void {
		this.trackConclusionModal("close");
		this.closeModalFinish();
	}

	private closeModalFinish(): void {
		this.hide();
		this.atlasModalService.closeModal();
	}

	protected closeModalButton(): void {
		const userId = this.authService.getUserLogin()?.idUsuario || 0;
		this.loadingFinishOnboardingBtn.set(true);
		this.trackConclusionModal("finish");
		this.onboardingUserService
			.updateOnboardingUser(userId, {
				...this.actualOnboardingUserData(),
				checklist: false
			})
			.subscribe({
				next: () => {
					this.loadingFinishOnboardingBtn.set(false);
					this.onboardingUserService.emitForceUpdateOnboardingUser();
					this.closeModalFinish();
				},
				error: () => {
					this.loadingFinishOnboardingBtn.set(false);
				}
			});
	}
}
