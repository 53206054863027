<div class="modal-atlas">
	<div class="modal-header modal-highlight">
		<atlas-modal
			[header]="'MODAL_WELCOME.HEADER' | translate"
			open
			header-illustration="happy-woman-notebook"
			disable-auto-close=""
			(atlas-modal-close)="closeModal()"
		>
			<atlas-layout alignment="center" gap="6">
				<atlas-heading>
					<strong>{{ "MODAL_WELCOME.TITLE" | translate }}</strong>
				</atlas-heading>

				<atlas-text>
					{{ "MODAL_WELCOME.MESSAGE.NAVIGATE" | translate }}
					<strong>{{
						"MODAL_WELCOME.MESSAGE.PARTNER" | translate
					}}</strong>
				</atlas-text>
				<atlas-text>
					{{ "MODAL_WELCOME.MESSAGE.LOAD_INFO" | translate }}
				</atlas-text>
				<atlas-text>{{
					"MODAL_WELCOME.MESSAGE.START" | translate
				}}</atlas-text>
			</atlas-layout>

			<atlas-button
				[description]="'MODAL_WELCOME.BUTTON_TITLE' | translate"
				theme="primary"
				slot="actions"
				(click)="startNowButton()"
			></atlas-button
		></atlas-modal>
	</div>
</div>
