import { Inject, Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { OnboardingUser } from "@data/schemas/onboarding-user";
import { HttpClient } from "@angular/common/http";
import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { AuthService } from "@core/services/auth/auth.service";
import { LoginResponse } from "@shared/schemas/complete-login";

@Injectable({
	providedIn: "root"
})
export class OnboardingUserService {
	private loading = new BehaviorSubject<boolean>(false);
	loading$ = this.loading.asObservable();

	onbordingUserData = signal<OnboardingUser | undefined>(undefined);

	private forceUpdateOnboardingUser =
		new BehaviorSubject<LoginResponse | null>(null);
	forceUpdateOnboardingUser$ = this.forceUpdateOnboardingUser.asObservable();

	public showWelcomeModal = signal(false);

	readonly SHOW_WELCOME_DATE_ENDPOINT =
		"onboarding-user/show-onboarding-user";

	readonly UPDATE_WELCOME_DATE_ENDPOINT =
		"onboarding-user/update-onboarding-user";

	constructor(
		private baseCrudBff: BaseCrudBffService,
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	getOnboardingUser(id: number): Observable<OnboardingUser> {
		return this.baseCrudBff
			.getById<OnboardingUser>(this.SHOW_WELCOME_DATE_ENDPOINT, id)
			.pipe(
				tap((onboardingUser) =>
					this.onbordingUserData.set(onboardingUser)
				)
			);
	}

	updateOnboardingUser(
		id: number,
		onboardingUser: Partial<OnboardingUser>
	): Observable<Partial<OnboardingUser>> {
		return this.http
			.put<Partial<OnboardingUser>>(
				`${this.config.environment.API_BFF}/${
					this.UPDATE_WELCOME_DATE_ENDPOINT
				}/${id}?hashedFingerprint=${
					this.authService.getHashedFingerprint() ?? ""
				}`,
				onboardingUser,
				{
					withCredentials: true
				}
			)
			.pipe(tap(() => this.showWelcomeModal.set(false)));
	}

	emitForceUpdateOnboardingUser(): void {
		this.forceUpdateOnboardingUser.next(this.authService.getUserLogin());
	}
}
