import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { ChecklistSidebarItemComponent } from "../checklist-sidebar-item/checklist-sidebar-item.component";
import { TranslateModule } from "@ngx-translate/core";
import { OnboardingUser } from "@data/schemas/onboarding-user";

@Component({
	selector: "app-checklist-sidebar-body",
	standalone: true,
	imports: [CommonModule, ChecklistSidebarItemComponent, TranslateModule],
	templateUrl: "./checklist-sidebar-body.component.html",
	styleUrl: "./checklist-sidebar-body.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistSidebarBodyComponent {
	public authorityType = input.required<string | undefined>();
	public onboardingUserData = input.required<OnboardingUser | undefined>();
}
