import { CommonModule } from "@angular/common";
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewChild,
	signal
} from "@angular/core";
import { YouTubePlayer } from "@angular/youtube-player";
import { OnboardingService } from "@modules/home/service/onboarding.service";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";
import { IAtlasModal } from "@shared/types/atlas/i-atlas-modal";
import { CHECKLIST_STEP_KEYS } from "../data/checklist-step";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { checklistStepKeyToTrackingKey } from "../data/checklist-step-key-to-tracking-key";

@Component({
	selector: "app-checklist-sidebar-modal",
	standalone: true,
	imports: [CommonModule, YouTubePlayer],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	templateUrl: "./checklist-sidebar-modal.component.html",
	styleUrl: "./checklist-sidebar-modal.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistSidebarModalComponent {
	constructor(
		private atlasModalService: AtlasModalService,
		private onboardingService: OnboardingService,
		private segment: CustomSegmentService
	) {}

	@ViewChild("atlasModal")
	atlasModal!: ElementRef<IAtlasModal>;

	@Input({ required: true })
	title = "";

	@Input()
	authority = "";

	@Input()
	key: CHECKLIST_STEP_KEYS = "FIRST_ACCESS";

	@Input({ required: true })
	videoId = "";

	@Input({ required: true }) description = "";

	@Input({ required: true })
	okButtonText = "";

	@Input({ required: true })
	altButtonTextPlayerYt = "";

	private ytPlayAlreadyTracked = signal(false);

	private getTrackingKeyModal(): string {
		return `modal_step_${checklistStepKeyToTrackingKey.get(this.key)}`;
	}

	private hide(): void {
		this.atlasModal.nativeElement?.closeModal?.();
	}

	private fireTrackingModal(action: "close" | "got_it" | "play"): void {
		this.segment.track({
			action,
			table: "base_erp_onboarding",
			screen: this.getTrackingKeyModal()
		});
	}

	protected closeModal(): void {
		this.fireTrackingModal("close");
		this.hide();
		this.atlasModalService.closeModal();
	}

	protected closeModalButton(): void {
		this.onboardingService.setCloseModalStep(this.key);
		this.fireTrackingModal("got_it");
		this.hide();
		this.atlasModalService.closeModal();
	}

	protected onPlayerReady(event: YT.OnStateChangeEvent): void {
		if (
			event.data === YT.PlayerState.PLAYING &&
			!this.ytPlayAlreadyTracked()
		) {
			this.ytPlayAlreadyTracked.set(true);
			this.fireTrackingModal("play");
		}
	}
}
