<atlas-modal
	#atlasModal
	header-illustration="woman-check-bars"
	open=""
	[disableAutoClose]="true"
	(atlas-modal-close)="closeModal()"
>
	<h5 class="title text-dark fw-semibold text-center atlas-spacing-mb-3">
		{{ "ONBOARDING.FINISHED_MODAL.TITLE" | translate }}
	</h5>

	<p class="text-center mb-0">
		<span
			(click)="trackHelpCenterClick()"
			[innerHTML]="'ONBOARDING.FINISHED_MODAL.DESCRIPTION' | translate"
		></span>
	</p>

	<atlas-button
		(click)="closeModalButton()"
		[description]="'ONBOARDING.FINISHED_MODAL.BUTTON' | translate"
		theme="primary"
		slot="actions"
		[loading]="loadingFinishOnboardingBtn()"
	></atlas-button
></atlas-modal>
